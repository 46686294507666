html, body {
    margin: 0;
    background: #101010;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#weight-input {
    padding: 50px;
    
}

#weight-to-store {
    color: #eee;
    font-size: 5em;
}

#weight-unit {
    color: #888;
    font-size: 1.5em;
}

.enter-info {
    color: #fff;
}

#keyboard {
    position: fixed;
    bottom: 112px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1dvh;
    width: 100%;
}

#keyboard .tile {
    display: flex;
    justify-content: center;
    align-items: center;
}

#keyboard .tile input {
    width: calc(100% - 1vh);
    height: 8vh;
    background: transparent;
    color: #999;
    font-size: 1.7em;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: .2s;
}

#keyboard .tile input:active {
    border: 2px solid #303030;
}
