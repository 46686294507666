.container {
    position: fixed;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    bottom: 0;
    width: 100%;
    height: 100px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}