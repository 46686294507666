input[type="radio"]:checked {
    visibility: hidden;
}

.step {
    margin: 0 20px;
    width: 100%;
}

.stepGroupTitle {
    color: #fff;
}

.inputCloudLabel {
    margin: 0 5px 5px 0;
    padding: 8px 16px;
    display: inline-block;
    background: #fff;
    border-radius: 8px;
}

.inputCloud {
    margin: 0 5px 5px 0;
    
    //display: inline-block;
    display: block;
    background: #fff;
    color: #000;
    
    display: none;
}

.inputCloudActive {
    background: orange !important;
}